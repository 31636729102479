// All dark style overrides should live here

@use "../../theme/colors";

.bg-dark {
  color: $dark-text-color;

  h2, h4 {
      color: $dark-text-color;
  }

  a:not(.dark-exempt), .btn-link {
    color: $dark-hover-color;
    > i {
      color: $dark-hover-color !important;
    }
  }

  hr {
    background-color: colors.$dark-form-border;
  }

  a.btn {
    color: white;
  }

  .accordion-header {
    font-weight: bold;
    color: $dark-primary-color;
  }

  


  .accent {
    background-color: $dark-form-background !important;
    color: lightgray !important;
    box-shadow: inset 0px 0px 8px 1px $dark-form-background !important;
  }

  .text-muted {
    color: #d7d7d7 !important;
  }

  .breadcrumb {
    background-color: $dark-item-accent-bg;

    .breadcrumb-item {
      color: $dark-text-color;
    }
  }

  .btn-information, .btn-outline-secondary, pre {
      color: $dark-text-color;
  }

  .rating-star, .close, i.fa {
    color: $dark-icon-color;
  }

  .modal-content {
    color: $dark-text-color;
    background-color: $dark-bg-color;
  }

  input:not(read-only), textarea, .autocomplete-container .input-container input, .form-control, .typeahead-input {
    color: $dark-text-color;
    background-color: $dark-bg-color;
  }

  input:read-only, input:disabled {
      background-color: $dark-form-readonly;
  }

  input::placeholder {
    color: $dark-form-placeholder;
  }

  .autocomplete-container .input-container .x {
    color: $dark-text-color;
  }
  .autocomplete-container .not-found {
    color: $dark-text-color;
    background-color: $dark-bg-color;
  }

  .autocomplete-container .input-container .x i {
    color: $dark-icon-color;
  }
  .autocomplete-container .suggestions-container, .dropdown-menu, .list-group-item {
    color: $dark-text-color;
    background-color: $dark-bg-color;
    border-color: $dark-form-border;
  }

  .dark-menu {
    background-color: $dark-form-background-no-opacity;
    border-color: $dark-form-background;
  }

  .dark-menu-item {
    color: $dark-text-color;
    background-color: $dark-form-background-no-opacity;
    border-color: $dark-form-background;
  }

  .dropdown .list-group-item:hover {
    background-color: $dark-hover-color;
  }

  .ngx-slider .ngx-slider-bubble {
      color: $dark-text-color !important;
  }

  .page-link {
      background-color: $dark-form-background;
      border-color: $dark-form-border;
  }

  .page-item.disabled .page-link {
    background-color: $dark-form-background;
    border-color: $dark-form-border;
  }

  .nav-tabs, .tagbadge {
      border-color: $dark-form-border;
  }

  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link, .tagbadge {
      border-color: $dark-form-border;
      color: $dark-text-color;
      background-color: $dark-primary-color;
  }
  .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: $dark-hover-color;
  }
  

  .dropdown-menu {
    background-color: #171719;
    .dropdown-item:hover, .dropdown-item:focus {
        color: $dark-text-color;
        background-color: $dark-hover-color;
    }
    .dropdown-item {
        color: $dark-text-color;
    }
  }

  .read-more-link {
    color: $dark-text-color !important;
  }

  .login {

    &::before {
      content: "";
      background-image: url('../../assets/images/login-bg.jpg');
      background-size: cover;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0.05;
      width: 100%;
    }

    .card {
      background-color: $dark-primary-color !important;
    }

    input {
      color: #000;
    }
  }
  .card { 
    background-color: $dark-card-color;
    color: $dark-text-color;
    border-color: $dark-form-border;

    div[role="tabpanel"] {
      background-color: rgba(52, 60, 70, 0.5); // This is a good accent color
    }
  }

  .section-title {
    color: $dark-text-color;
  }

  .error-banner, .dropdown-toggle {
    color: #efefef;
  }

  .nav-events, .nav-events .popover-body {
    background-color: $dark-form-background-no-opacity;
  }

  .bs-popover-bottom > .arrow::after, .bs-popover-bottom > .arrow::before {
    border-bottom-color: transparent;
  }

}


/*
// Dark mode Toastr Styles
*/
.bg-dark .toast-success {
  background-color: $dark-primary-color
}

.bg-dark .toast-error {
  background-color: #BD362F;
}

.bg-dark .toast-info {
  background-color: #2F96B4;
}

.bg-dark .toast-warning {
  background-color: #F89406;
}