// Import colors for overrides of bootstrap theme
@import './theme/colors';
@import './theme/toastr';

// Bootstrap must be after _colors since we define the colors there
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap/scss/mixins/_breakpoints.scss';

@import '~swiper/swiper.scss';

@import './assets/themes/dark.scss';

// Global Styles
button:disabled, .form-control:disabled, .form-control[readonly], .disabled, :disabled {
  cursor: not-allowed;
}

label, select, .clickable {
  cursor: pointer;
}

.btn-primary {
  color: white;
}

:root {
  color-scheme: light;
}

// :root .bg-dark {
//   color-scheme: dark;
// }

@font-face {
  font-family: "EBGarmond";
  src: url("assets/fonts/EBGarmond/EBGaramond-VariableFont_wght.ttf") format("truetype");
}

@font-face {
  font-family: "Spartan";
  src: url("assets/fonts/Spartan/Spartan-VariableFont_wght.ttf");
}

html, body { height: 100%; }
body {
  margin: 0; 
  font-family: "EBGaramond", "Helvetica Neue", sans-serif;
}


.btn-icon {
  cursor: pointer;
}

.btn.btn-secondary.alt {
  background-color: #424c72;
  border-color: #444f75;

  &:hover {
    background-color: #3b4466; 
  }

  &:focus {
    background-color: #343c59;
    box-shadow: 0 0 0 0.2rem rgb(68 79 117 / 50%); 
  }
}


app-root {
  background-color: inherit;
}


// Utiliities
@include media-breakpoint-down(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)) {
  .phone-hidden {
    display: none;
  }
}

@include media-breakpoint-up(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)) {
  .not-phone-hidden {
    display: none;
  }
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

// Debug styles
.redlines * {
  outline: 1px solid red;
  outline-offset: -1px;
}