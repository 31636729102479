$primary-color: #4ac694; //(74,198,148)
$error-color: #ff4136; // #bb2929 good color for contrast rating

$dark-bg-color: #343a40;
$dark-card-color: rgba(22,27,34,0.5);
$dark-primary-color: rgba(74, 198, 148, 0.9);
$dark-text-color: #efefef;
$dark-hover-color: #4ac694;
$dark-form-background: rgba(1, 4, 9, 0.5);
$dark-form-background-no-opacity: rgb(1, 4, 9);
$dark-form-placeholder: #efefef;
$dark-link-color: rgb(88, 166, 255);
$dark-icon-color: white;
$dark-form-border: rgba(239, 239, 239, 0.125);
$dark-form-readonly: #434648;
$dark-item-accent-bg: #292d32;


$white-item-accent-bg: rgba(247, 247, 247, 1);


//=========================
//     Ratings
//=========================
$rating-filled: $primary-color;
$rating-empty: #b0c4de;

//=========================
//    Drawers
//=========================
// :root {
//   --drawer-background-color: #FFF;
// }


$theme-colors: (
  "primary": $primary-color,
  "danger": $error-color 
);
